import { createRouter, createWebHistory } from 'vue-router';
import { useAuthStore } from '../stores/auth.store';
import DefaultLayout from '../layouts/DefaultLayout.vue';
import BaseLayout from '../layouts/BaseLayout.vue';
import { supabase } from '../supabase';

export const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  linkActiveClass: 'active',
  routes: [
    {
      name: 'Users',
      path: '/',
      component: () => import('../views/UsersPage.vue'),
      meta: { requiresAuth: true, layout: BaseLayout, roles: ['admin'] },
    },
    {
      name: 'Events',
      path: '/events',
      component: () => import('@/views/EventsView.vue'),
      meta: { requiresAuth: true, layout: BaseLayout, roles: ['admin'] },
    },
    {
      name: 'Login',
      path: '/signin',
      component: () => import('@/views/Auth/LoginView.vue'),
      meta: { layout: DefaultLayout },
    },
    {
      name: 'RedirectAfterLogin',
      path: '/redirect',
      meta: { layout: DefaultLayout },
      component: () => import('@/views/Auth/RedirectAfterLogin.vue'),
    },
    {
      name: '404',
      path: '/404',
      meta: { layout: DefaultLayout },
      component: () => import('@/views/404View.vue'),
    },
    {
      name: 'orders',
      path: '/orders/:id?',
      component: () => import('../views/OrdersPage.vue'),
      meta: { requiresAuth: true, layout: BaseLayout, roles: ['admin'] },
    },
    {
      name: 'Designs',
      path: '/designs/:id?/:task_id?',
      alias: '/collections/:id?/:task_id?',
      props: (route) => ({ id: Number(route.params.id) }),
      component: () => import('../views/CollectionsView.vue'),
      meta: { requiresAuth: true, layout: BaseLayout, roles: ['admin', 'designer', 'seller'] },
    },
    {
      name: 'Constructor',
      path: '/constructor/:product_id?',
      component: () => import('../views/ConstructorView.vue'),
      meta: { requiresAuth: true, layout: BaseLayout, roles: ['admin', 'designer', 'seller'] },
    },
    // {
    //   name: 'Shipments-old',
    //   path: '/shipments_old/:id?',
    //   component: () => import('../views/ShipmentsView.vue'),
    //   meta: { requiresAuth: true, layout: BaseLayout, roles: ['admin'] },
    // },
    {
      name: 'Shipments table',
      path: '/shipments',
      component: () => import('../views/ShipmentsTableView.vue'),
      meta: { requiresAuth: true, layout: BaseLayout, roles: ['admin'] },
    },
    // {
    //   name: 'logistics_order_by_id',
    //   path: '/logistics/orders/:id',
    //   component: () => import('@/components/logistics/logistics.vue'),
    //   meta: { requiresAuth: true, layout: BaseLayout, roles: ['admin', 'seller'] },
    // },
    // {
    //   name: 'delivery',
    //   path: '/delivery',
    //   component: () => import('@/components/logistics/logisticsShipping.vue'),
    //   meta: { requiresAuth: true, layout: BaseLayout, roles: ['admin', 'logistics'] },
    // },
    {
      name: 'invoices',
      path: '/invoices',
      component: () => import('../views/InvoicesView.vue'),
      meta: { requiresAuth: true, layout: BaseLayout, roles: ['accountant', 'admin'] },
    },
    {
      name: 'inventory',
      path: '/inventory/:company_id?',
      component: () => import('../views/InventoryView.vue'),
      meta: { requiresAuth: true, layout: BaseLayout, roles: ['accountant', 'admin'] },
    },
    {
      name: 'no-access',
      path: '/forbidden',
      component: () => import('@/pages/NoAccess.vue'),
      meta: { requiresAuth: false, layout: BaseLayout },
    },
    {
      name: 'profile',
      path: '/profile',
      component: () => import('../views/ProfileView.vue'),
      props: { pageName: 'Profile' },
      meta: { requiresAuth: true, layout: BaseLayout, roles: ['admin'] },
    },
    {
      name: 'Logout',
      path: '/logout',
      beforeEnter: async () => {
        await supabase.auth.signOut();
        return { path: '/signin' };
      },
      meta: { requiresAuth: false },
    },
    { path: '/:catchAll(.*)', redirect: '/404' },
  ],
});

router.beforeEach(async (to) => {
  const { user, getUserRole } = useAuthStore();
  if (user) {
    const user_role = getUserRole();
    if (to.meta.roles && user) {
      if (!to.meta.roles.includes(user_role)) {
        return {
          path: '/forbidden',
        };
      }
    }
  }
  if (to.meta.requiresAuth && !user) {
    return {
      path: '/signin',
      query: { redirect: to.fullPath },
    };
  }
});
